import React from "react";
import { useLocation } from "react-router-dom";
import "./index.css";

const MyResult = () => {
  const { state } = useLocation();
  console.log("state", state);
  return (
    <>
      <section id="banner" class="Banner_others section_gaps faq">
        <div class="container">
          <div class="row d_flex">
            <div class="col-lg-6 m-auto">
              <div class="left">
                <h1>প্রস্তুতিতে প্রস্তুত হোন</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="attend_quiz" class="section_gaps">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6 col-12 m-auto">
              <div class="attend_quiz_item">
                <div class="img">
                  <img
                    class="img-fluid w-sm-50 w-md-100"
                    src="https://bcsconfidence.prostuti.net/admin/media/logo/logo-635c0e5b31136.png"
                    alt=""
                  />
                </div>
                <div class="text">
                  <h3> Full Overview </h3>

                  <br />
                  <br />
                  {+state.event_info.show_resut ? (
                    <>
                      <p>ইভেন্ট - {state.result.event_name}</p>
                      <p>টোটাল Level - 1</p>
                      <p>টোটাল MCQ - {state.result.total_mcq}</p>
                      <p>উত্তর দেওয়া হয়নি - {state.result.total_unanswered}</p>
                      <p>টোটাল সঠিক - {state.result.total_correct}</p>
                      <p>টোটাল ভুল - {state.result.total_wrong}</p>
                      <p>টোটাল Mark - {state.result.total_mark}</p>
                      <p>
                        টোটাল Time - {(state.result.total_time / 60).toFixed(2)}{" "}
                        mins
                      </p>
                    </>
                  ) : (
                    <>

                      <p>ইভেন্ট - {state.result.event_name}</p>
                      <p>Result Not Published Yet</p>

                    </>
                  )}


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyResult;
